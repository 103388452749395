/* src/App.css */

/* General App Styles */
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.App h1 {
  margin-bottom: 20px;
  text-align: center;
}

/* Visualization Container */
.visualization-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* Add Section Button */
.add-section-button {
  margin: 20px 0;
  padding: 10px 20px;
  background-color: #0088FE;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-section-button:hover {
  background-color: #005bb5;
}

/* Loading and Error States */
.loading, .error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loading p, .error p {
  margin-top: 10px;
  font-size: 18px;
}

/* Tooltip Styles */
.react-tooltip {
  font-size: 14px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.75) !important;
  color: white !important;
  border-radius: 4px !important;
}

/* src/App.css */

/* Smooth Transitions for All Elements */
* {
  transition: all 0.3s ease;
}

/* Button Focus States */
button:focus, .filter-dropdown:focus, .search-bar:focus, .dataset-option input:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 136, 254, 0.5);
}

/* Scrollbar Styling for WebKit Browsers */
.scrollable-container::-webkit-scrollbar {
  width: 8px;
}

.scrollable-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 10px;
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background: #999999;
}