/* src/components/VisualizationSection.css */

/* General Visualization Section Styling */
.visualization-section {
  border: none;
  margin: 20px;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 1200px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 20px;
  transition: transform 0.3s ease;
}

.visualization-section:hover {
  transform: translateY(-5px);
}

/* Section Header */
.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.section-header h2 {
  font-size: 1.8em;
  margin: 0;
  flex: 1;
  color: #333333;
}

.remove-section-button {
  padding: 10px 20px;
  background-color: #ff4d4d;
  color: #ffffff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.3s, transform 0.3s;
}

.remove-section-button:hover {
  background-color: #cc0000;
  transform: scale(1.05);
}

/* Search and Filters Container */
.search-filter-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
}

/* Search Bar */
.search-bar {
  flex: 1 1 250px;
  padding: 10px 15px 10px 40px; /* Padding left for icon */
  border: 1px solid #cccccc;
  border-radius: 30px;
  font-size: 1em;
  background-color: #f9f9f9;
  position: relative;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.search-bar:focus {
  outline: none;
  border-color: #0088FE;
  box-shadow: 0 0 5px rgba(0, 136, 254, 0.5);
}

/* Search Icon */
.search-filter-container .search-bar::before {
  content: '🔍';
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 1em;
  color: #999999;
}

/* Filter Dropdowns */
.filter-dropdown {
  flex: 0 1 180px;
  padding: 10px 15px;
  border: 1px solid #cccccc;
  border-radius: 25px;
  background-color: #f9f9f9;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12'%3E%3Cpath fill='%23999' d='M6 8l-6-6h12z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: 12px;
  font-size: 1em;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.filter-dropdown:focus {
  outline: none;
  border-color: #0088FE;
  box-shadow: 0 0 5px rgba(0, 136, 254, 0.5);
}

/* Toggle Select Button */
.toggle-select-button {
  flex: 0 1 200px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: #ffffff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.3s, transform 0.3s;
}

.toggle-select-button:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

/* Dataset Selector */
.dataset-selector {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

/* Scrollable Container */
.scrollable-container {
  max-height: 250px; /* Increased for better visibility */
  overflow-y: auto;
  padding-right: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: inset 0 2px 4px rgba(0,0,0,0.05);
}

/* Dataset Category */
.dataset-category {
  display: flex;
  flex-direction: column;
}

.dataset-category h5 {
  margin-bottom: 10px;
  font-size: 1.2em;
  color: #555555;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 5px;
}

/* Dataset Options Horizontal */
.dataset-options-horizontal {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

/* Dataset Option Styling */
.dataset-option {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 8px 20px;
  border-radius: 20px;
  font-size: 0.95em;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.dataset-option:hover {
  background-color: #e0e0e0;
  transform: translateY(-2px);
}

.dataset-option input {
  margin-right: 10px;
  width: 18px;
  height: 18px;
  cursor: pointer;
  appearance: none;
  background-color: #ffffff;
  border: 2px solid #cccccc;
  border-radius: 4px;
  position: relative;
  transition: border-color 0.3s, background-color 0.3s;
}

/* Custom Checkbox Checked State */
.dataset-option input:checked {
  background-color: #0088FE;
  border-color: #0088FE;
}

.dataset-option input:checked::after {
  content: '✔';
  position: absolute;
  top: -2px;
  left: 4px;
  color: #ffffff;
  font-size: 1em;
}

/* No Datasets Message */
.no-datasets {
  font-style: italic;
  color: #777777;
  padding: 10px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .section-header {
	flex-direction: column;
	align-items: flex-start;
  }

  .remove-section-button {
	margin-left: 0;
	margin-top: 10px;
  }

  .search-filter-container {
	flex-direction: column;
	align-items: stretch;
  }

  .search-bar, .filter-dropdown, .toggle-select-button {
	flex: 1 1 auto;
  }

  .dataset-category {
	min-width: 150px;
  }
}